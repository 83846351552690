import AuthView from "../views/auth/AuthView";
import MainView from "../views/MainView";
import Onderzoek from "../views/Onderzoek";
import Proefabonnement from "../views/Proefabonnement";

let routes = [
  {
    path: "/auth",
    component: AuthView,
    layout: "auth",
  },
  // {
  //   path: "/",
  //   component: MainView,
  //   layout: "main",
  // },
  // {
  //   path: "/landelijktvonderzoek",
  //   component: Onderzoek,
  //   layout: "main",
  // },
  {
    path: "/proefabonnement",
    component: Proefabonnement,
    layout: "main",
  },
];
export default routes;
